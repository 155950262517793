import React from 'react'
import { Input } from '../../components/Input'
import { ProductModel } from './ProductsPage'

interface ProductFormProps {
    disabled?: boolean
    editing?: boolean
    product?: Partial<ProductModel>
    handleSubmit: (i: Partial<ProductModel>) => void
    toogle?: any
}

export class ProductForm extends React.Component<ProductFormProps> {
    state = {
        product: {
            name: "",
            sellPrice: 0.00,
            stock: 0.00
        }
    }

    componentDidMount() {
        if (this.props.product) {
            this.setState({
                ...this.state,
                product: this.props.product
            })
        }
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name
        const value = event.target.value
        this.setState({
            ...this.state,
            product: {
                ...this.state.product,
                [name]: value
            }
        })
    }

    handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        this.props.handleSubmit(this.state.product)
    }

    render() {
        const product = this.state.product
        const { disabled = false, editing = true } = this.props

        return (
            <div>
                {!this.props.product &&
                    <button className='btn btn-secondary' onClick={() => this.props.toogle()}>Regresar al listado</button>
                }
                <form onSubmit={this.handleSubmit}>
                    <fieldset disabled={disabled}>
                        <div className="row mt-2">
                            <div className="col">
                                <Input
                                    type="text"
                                    name="name"
                                    label="Nombre del producto"
                                    value={product.name}
                                    handleChange={this.handleChange}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <Input
                                    type="number"
                                    name="sellPrice"
                                    label="Precio por mayor"
                                    value={product.sellPrice}
                                    handleChange={this.handleChange}
                                    required={true}
                                />
                            </div>
                            <div className="col">
                                <Input
                                    type="number"
                                    name="stock"
                                    label="Stock actual"
                                    value={product.stock}
                                    disabled={this.props.product && true}
                                    handleChange={this.handleChange}
                                />
                            </div>
                        </div>
                        {editing &&
                            <div className="d-grid">
                                <button className="btn btn-primary mt-2" type="submit">Guardar</button>
                            </div>
                        }
                    </fieldset>
                </form>
            </div>
        )
    }
}