import { useHistory } from 'react-router';
import { Spinner } from 'react-bootstrap'
import { fetchProduct, updateProduct } from '../../utils/api/products'
import { PageLayout } from '../../components/PageLayout';
import { ProductForm } from './ProductForm';
import { ProductModel } from './ProductsPage';
import withFecthOne, { WraperFetchOneProps } from '../../hocs/withFetchOne';
import { deleteProduct } from '../../services/products';
import routes from '../../helpers/routes';

function Details(props: WraperFetchOneProps<ProductModel>) {
    const history = useHistory();
    const { loading, disable, editing, fetchItem, handleEditing, handleSubmit } = props

    const handleDelete = async (productId: number) => {
        try {
            await deleteProduct(productId);
            alert('Producto Eliminado');
            history.push(routes.products);
        } catch (e) {
            alert(JSON.stringify(e, null, 2))
        }
    }

    if (loading) return <PageLayout><Spinner animation="border" /></PageLayout>

    return (
        <PageLayout>
            <button className="btn btn-secondary" onClick={() => history.push(".")}>Regresar</button>
            <h1 className="mt-2">{fetchItem.name}</h1>
            <ProductForm product={fetchItem} disabled={disable} editing={editing} handleSubmit={handleSubmit} />
            {!editing ?
                <div className="row mt-2">
                    <div className="col">
                        <div className="d-grid">
                            <button className="btn btn-warning" onClick={handleEditing}>Editar</button>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-grid">
                            <button className="btn btn-danger ms-2" onClick={() => {
                                if (window.confirm('¿Seguro que desea eliminar el producto?')) {
                                    handleDelete(fetchItem.id)
                                }
                            }}>Eliminar</button>
                        </div>
                    </div>
                </div>
                :
                <div className="d-grid mt-2">
                    <button className="btn btn-secondary" onClick={() => {
                        if (window.confirm('¿Desea cancelar la edición?')) {
                            history.push(".")
                        }
                    }}>Cancelar</button>
                </div>
            }
        </PageLayout>
    )
}

const ProductDetails = withFecthOne(Details)(fetchProduct, updateProduct);
export default ProductDetails