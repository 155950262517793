import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { PageLayout } from "../../components/PageLayout";
import useModal from "../../hooks/useModal";
import { axiosInstance } from "../../utils/axios";
import ChangeUserPasswordModal from "./ChangeUserPasswordModal";
import EditUserDetailsModal from "./EditUserDetailsModal";
import { UserInfoCaption, UserInfoContainer, UserInfoDetail, UserInfoRow } from "./styled";

export default function AccountPage() {
    const [user, setUser] = useState<any>();
    const [error, setError] = useState(null);
    const editModal = useModal()
    const passModal = useModal()

    const getUser = useCallback(async () => {
        try {
            const res = await axiosInstance.get(`/users/${localStorage['userId']}`)
            setUser(res.data)
        } catch (error: any) {
            setError(error)
        }
    }, [])

    useEffect(() => {
        getUser()
    }, [getUser])

    if (error) {
        <PageLayout>{error}</PageLayout>
    }

    if (!user) {
        <PageLayout>Cargando...</PageLayout>
    }

    return (
        <PageLayout>
            <h3>Hola, estos son los datos de tu cuenta:</h3>
            {user &&
                <>
                    <UserInfoContainer className="mt-3 mb-3">
                        <UserInfoRow>
                            <UserInfoCaption>Nombres</UserInfoCaption>
                            <UserInfoDetail>{user.first_name}</UserInfoDetail>
                        </UserInfoRow>
                        <UserInfoRow>
                            <UserInfoCaption>Apellidos</UserInfoCaption>
                            <UserInfoDetail>{user.last_name}</UserInfoDetail>
                        </UserInfoRow>
                        <UserInfoRow>
                            <UserInfoCaption>Usuario</UserInfoCaption>
                            <UserInfoDetail>{user.username}</UserInfoDetail>
                        </UserInfoRow>
                        <UserInfoRow>
                            <UserInfoCaption>Email</UserInfoCaption>
                            <UserInfoDetail>{user.email}</UserInfoDetail>
                        </UserInfoRow>
                        <UserInfoRow>
                            <UserInfoCaption>Rol</UserInfoCaption>
                            <UserInfoDetail>{user.role}</UserInfoDetail>
                        </UserInfoRow>
                    </UserInfoContainer>
                    <Button onClick={() => editModal.toogleModal()} className="me-2" variant="warning">Editar datos</Button>
                    <Button onClick={() => passModal.toogleModal()} variant="danger">Cambiar contraseña</Button>
                    <EditUserDetailsModal show={editModal.isOpenModal} close={editModal.toogleModal} user={user} refresh={getUser} />
                    <ChangeUserPasswordModal show={passModal.isOpenModal} close={passModal.toogleModal} user={user} refresh={getUser} />
                </>}
        </PageLayout>
    )
}
