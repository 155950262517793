import styled from 'styled-components'

export const ActionButton = styled.div`
  padding: 3px;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  background: transparent;
  &:hover {
    color: white;
  }
`

export const ConfirmButton = styled(ActionButton)`
  border: 1px solid green;
  color: green;
  &:hover {
    background: green;
  }
`

export const ViewButton = styled(ActionButton)`
  border: 1px solid blue;
  color: blue;
  &:hover {
    background: blue;
  }
`

export const DeliveryButton = styled(ActionButton)`
  border: 1px solid indigo;
  color: indigo;
  &:hover {
    background: indigo;
  }
`

export const PayButton = styled(ActionButton)`
  border: 1px solid gold;
  color: gold;
  &:hover {
    background: gold;
  }
`

export const EmitButtom = styled(ActionButton)`
  border: 1px solid grey;
  color: grey;
  &:hover {
    background: grey;
  }
`

export const OrderView = styled.div`
    display: grid;
    grid-template-areas: "generalDetails moneyDetails" "items items";
    row-gap: 1.5em;
    column-gap: 2em;
`

export const OrderGeneralDetails = styled.div`
    grid-area: generalDetails;
    display: flex;
    flex-direction: column;
`

export const OrderGeneralDetailsLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const OrderInfoLabel = styled.div`
    font-weight: bold;
    font-size: 1rem;
`

export const OrderInfo = styled.div`
    color: ${props => props.color || "darkslategray"};
    font-size: 1.1rem;
`

export const OrderInfoBold = styled(OrderInfo)`
    font-weight: bold;
`

export const OrderMoneyDetails = styled.div`
    grid-area: moneyDetails;
`

export const OrderItemsView = styled.div`
    grid-area: items;
`

export const OrderViewActions = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
`
export const EditAction = styled.div`
    text-decoration: none;
    cursor: pointer;
    display: flex;
    gap: .5em;
    border: 1px solid;
    border-radius: 5px;
    padding: .2em;
    color: goldenrod;
`