import React from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import routes from '../../../../helpers/routes';
import { formatMoney } from '../../../../utils/functions';

function OrderDetailsModal(props: any) {
  const history = useHistory();

  return (
    <Modal show={props.show} onHide={props.close}>
      <Modal.Header closeButton>
        <div style={{ fontWeight: 600 }}>Orden Nº {props.order.id}</div>
      </Modal.Header>
      <Modal.Body>
        <div><b>Vendedor:</b> {props.order.user.username}</div>
        <div><b>Cliente:</b> {props.order.customer.name}</div>
        <div><b>Referencia:</b> {props.order.customer.reference}</div>
        <div><b>Total:</b> {formatMoney(props.order.total)}</div>
        <div>Detalle de la venta:</div>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid' }}>Producto</th>
              <th style={{ border: '1px solid' }}>Cantidad</th>
            </tr>
          </thead>
          <tbody>
            {props.order.items.map((item: any, index: number) => (
              <tr key={index}>
                <td style={{ border: '1px solid' }}>{item.product_group.name}</td>
                <td style={{ border: '1px solid' }}>{item.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-info' onClick={() => history.push(routes.ventas.ver(props.order.id))}>Ver Venta</button>
      </Modal.Footer>
    </Modal>
  )
}

export default OrderDetailsModal