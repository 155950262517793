import React, { useState } from "react"
import styled from "styled-components"
import { CustomerSelection } from "./CustomerSelection"
import { VendorSelection } from "./VendorSelection";
import dayjs from "dayjs";
import { axiosInstance } from "../../../../utils/axios";

const FilterFormDiv = styled.div`
  padding: .5rem 1rem;
`

const StyledForm = styled.form`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`

function FiltersForm(props: any) {
  const [selectedCustomer, setSelectedCustomer] = useState('all');
  const [selectedVendor, setSelectedVendor] = useState('all');
  const [documentType, setDocumentType] = useState('all');
  const [desde, setDesde] = useState('');
  const [hasta, setHasta] = useState(dayjs().format('YYYY-MM-DD'));

  const handleSearch = async (e: any) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.get('/reports/sales/history', {
        params: {
          selectedCustomer,
          selectedVendor,
          documentType,
          desde,
          hasta
        }
      });
      props.setData(data)
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <FilterFormDiv>
      <StyledForm onSubmit={handleSearch}>
        <CustomerSelection value={selectedCustomer} selector={setSelectedCustomer}></CustomerSelection>
        <VendorSelection value={selectedVendor} selector={setSelectedVendor}></VendorSelection>
        <div className="desde">
          <span>Desde: </span>
          <input type="date" value={desde} onChange={(e) => setDesde(e.target.value)} />
        </div>
        <div className="hasta">
          <span>Hasta: </span>
          <input type="date" value={hasta} onChange={(e) => setHasta(e.target.value)} required />
        </div>
        <div className="documentType">
          <label htmlFor="type" className="me-2">Tipo de documento:</label>
          <select name="type" id="type" value={documentType} onChange={(e) => setDocumentType(e.target.value)}>
            <option value="all">Todos</option>
            <option value="FACTURA">Facturas</option>
            <option value="BOLETA">Boletas</option>
            <option value="PROFORMA">Guías</option>
          </select>
        </div>
        <input type="submit" value="Buscar ventas" />
      </StyledForm>
    </FilterFormDiv>
  )
}

export default FiltersForm