import React from "react";
import styled from "styled-components";

const SelectElement = styled.div`
display: flex;
flex-direction: column;
`;

interface SelectElementProps {
  name: string;
  label: string;
  arrayData: { id: string, value: string }[];
  value: string;
  setData: React.Dispatch<React.SetStateAction<string>>
}

function SelectElementComponent({ name, label, arrayData, value, setData }: SelectElementProps): JSX.Element {
  return (
    <SelectElement>
      <label htmlFor={name}>{label}:</label>
      <select name={name} id={name} value={value} onChange={(e) => setData(e.target.value)}>
        <option value="all">Todos</option>
        {arrayData.map(el => (
          <option key={el.id} value={el.id}>{el.value}</option>
        ))}
      </select>
    </SelectElement>
  );
}

export default SelectElementComponent;
