import dayjs from 'dayjs';
import React from 'react'
import styled from 'styled-components'
import { axiosInstance } from '../../../utils/axios';
import { CustomerSelection } from '../../sells/HistorySellOrders/FiltersForm/CustomerSelection'
import ProductGroupSelect from './ProductGroupSelect';

const MyForm = styled.form`
  display: flex;
  gap: 1rem;
`

const flex1 = { flex: 1 }

function Form(props: any) {
  const [customer, selectedCustomer] = React.useState('all');
  const [product, selectedProduct] = React.useState('all');
  const [desde, setDesde] = React.useState('');
  const [hasta, setHasta] = React.useState(dayjs().format('YYYY-MM-DD'));

  async function handleSubmit(e: any) {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.get('/reports/products/products-by-customers', {
        params:
          { selectedCustomer: customer, selectedProduct: product, desde, hasta }
      });
      props.setData(data)
    } catch (error) {
      alert(JSON.stringify(error, null, 2))
    }
  }

  return (
    <MyForm onSubmit={handleSubmit}>
      <div style={flex1}>
        <ProductGroupSelect value={product} selector={selectedProduct} />
        <CustomerSelection value={customer} selector={selectedCustomer} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'space-around' }}>
          <div>
            <span>Desde: </span>
            <input type="date" value={desde} onChange={(e) => setDesde(e.target.value)} />
          </div>
          <div>
            <span>Hasta: </span>
            <input type="date" value={hasta} onChange={(e) => setHasta(e.target.value)} required />
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <button type='submit'>Buscar</button>
        </div>
      </div>
    </MyForm>
  )
}

export default Form