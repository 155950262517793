import React from 'react'
import toast from 'react-hot-toast'
import { RouteComponentProps } from 'react-router'

interface IRouterParams {
  id: string
}

interface State<T> {
  loading: boolean
  disable: boolean
  editing: boolean
  fetchItem: T
}

function withFetchOne<T>(Component: React.ElementType) {
  return (fetchFunction: any, updateFunction: any, setPrice?: any) => class extends React.Component<RouteComponentProps<IRouterParams>> {
    state: State<T> = {
      loading: true,
      disable: true,
      editing: false,
      fetchItem: {} as T
    }

    fetcher() {
      const { id } = this.props.match.params
      fetchFunction(Number.parseInt(id)).then((obj: T) => {
        this.setState({
          ...this.state,
          loading: false,
          fetchItem: obj
        })
      })
    }

    componentDidMount() {
      this.fetcher()
    }

    refresh() {
      this.fetcher();
    }

    handleEditing = () => {
      this.setState({
        ...this.state,
        disable: !this.state.disable,
        editing: !this.state.editing
      })
    }

    handleSubmit = (obj: T) => {
      const { id } = this.props.match.params
      updateFunction(id, obj).then(() => {
        toast.success("Insumo actualizado correctamente");
        this.fetcher();
        this.setState({
          ...this.state,
          disable: true,
          editing: false
        })
      })
    }

    handleSetPrice = (obj: { providerId: number, price: number, currency: string }) => {
      const { id } = this.props.match.params
      setPrice(id, obj).then(() => {
        toast.success("Precio ingresado correctamente");
        this.fetcher();
      });
    }

    render() {
      return (
        <Component
          loading={this.state.loading}
          disable={this.state.disable}
          editing={this.state.editing}
          fetchItem={this.state.fetchItem}
          handleSetPrice={this.handleSetPrice}
          handleEditing={this.handleEditing}
          handleSubmit={this.handleSubmit}
          {...this.props}
        />
      )
    }
  }
}

export interface WraperFetchOneProps<T> {
  loading: boolean
  disable: boolean
  editing: boolean
  fetchItem: T
  handleSetPrice: (obj: { providerId: number, price: number, currency: string }) => void
  handleEditing: () => void
  handleSubmit: (obj: Partial<T>) => void
}

export default withFetchOne