import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import routes from '../../helpers/routes'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid grey;
  border-radius: 0.5rem;
  width: 200px;
  height: 200px;

  @media (max-width: 768px) {
    width: 40vw;
    height: 25vw;
  }
`

function SellBox() {
  return (
    <Box>
      <h3>Ventas</h3>
      <Link to={routes.ventas.index}>Go to ventas</Link>
    </Box>
  )
}

function DashboardsBox() {
  return (
    <Box>
      <h3>Dashboard</h3>
      <Link to={routes.dashboards}>Go to Dashboard</Link>
    </Box>
  )
}

function AdminHome() {
  return (
    <Container>
      <SellBox />
      <DashboardsBox />
    </Container>
  )
}

export default AdminHome