import styled from 'styled-components'

export const UserInfoContainer = styled.div`
  width: 40%;
`

export const UserInfoRow = styled.div`
  display: flex;
  gap: .3rem;
  margin-top: .1rem;
`

export const UserInfoCaption = styled.div`
  background: gainsboro;
  padding: .3rem;
  width: 30%;
  text-align: left;
`

export const UserInfoDetail = styled.div`
  background: darkslategray;
  color: white;
  padding: .3rem;
  width: 70%;
`