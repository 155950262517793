import { Button, Table } from "react-bootstrap";
import { PageLayout } from "../../components/PageLayout";
import useModal from "../../hooks/useModal";
import AddUserModal from "./AddUserModal";
import { useUsers } from "./useUsers";

export default function UsersPage() {
    const { users, refresh } = useUsers()
    const modal = useModal()

    return (
        <PageLayout>
            <h1>Usuarios del sistema</h1>
            <Button onClick={() => modal.toogleModal()}>Nuevo usuario</Button>
            <Table className="mt-3" bordered size="sm">
                <thead>
                    <tr>
                        <th>Nombre de usuario</th>
                        <th>Correo electrónico</th>
                        <th>Rol</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user: any) => (
                        <tr key={user.id}>
                            <td>{user.username}</td>
                            <td>{user.email}</td>
                            <td>{user.role}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <AddUserModal show={modal.isOpenModal} close={modal.toogleModal} refresh={refresh} />
        </PageLayout>
    )
}
